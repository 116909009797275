import { Injectable } from '@angular/core';
import { DownloadFormat, Email } from '../models/dataModel'

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

constructor() { }


downloadFile(data: any, filename='data', filter: string) {
  let csvData;
  if(data[0].hasOwnProperty("first_name")) {
    csvData = this.ConvertToCSV(data, ['serial_number', 'email', 'first_name', 'status'], filter);
  } else {
    csvData = this.ConvertToCSV(data, ['serial_number', 'email', 'status'], filter);
  }
  
  let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
  if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", filename + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}


ConvertToCSV(objArray: any, headerList: any, filter: string) {
  let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray; 

  if (filter === 'risky') {
    array = array.filter((book: Email) => book.status === 'Risky');
  }

  if (filter === 'undeliverable') {
    array = array.filter((book: Email) => book.status != 'Deliverable' && book.status != 'Risky');
  }

  if (filter === 'deliverable') {
    array = array.filter((book: Email) => book.status === 'Deliverable');
  }

  let str = '';
  let row = '';

  for (let index in headerList) {
      row += headerList[index] + ',';
  }
  row = row.slice(0, -1);
  str += row + '\r\n';
  for (let i = 0; i < array.length; i++) {
      
      let line = '';
      for (let index in headerList) {
        let head = headerList[index]; 
        line += array[i][head] + ',';
      }
      str += line + '\r\n';
  }
  return str;
}


}
