import { Component, Input, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-youtube-play',
  templateUrl: './youtube-play.component.html',
  styleUrls: ['./youtube-play.component.scss']
})
export class YoutubePlayComponent implements OnInit {

  @Input() youtube_link: string; 
  @Input() youtube_name: string; 
  videoUrl: SafeResourceUrl;

  constructor(public modalRef: MDBModalRef, private sanitizer: DomSanitizer) {
    
  }
  

  ngOnInit(): void {
    this.videoUrl = this.sanitizeUrl(this.youtube_link);
  }

  sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
