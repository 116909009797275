import { Component, OnInit, Input, } from '@angular/core';
import { Router } from '@angular/router'; 
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Batch } from '../../models/dataModel' ;
import { ClvApiService } from 'src/app/services/clv-api.service';

 
@Component({
  selector: 'app-textform',
  templateUrl: './textform.component.html',
  styleUrls: ['./textform.component.scss']
})
export class TextformComponent implements OnInit {
  
  @Input() googleUserID: any;

  errors: any;
  private itemsCollection: AngularFirestoreCollection<Batch>; 
  loading: boolean = false;
  
  constructor(
    private clvApiService: ClvApiService, 
    private router: Router, 
    private readonly afs: AngularFirestore
    ) {
      this.itemsCollection = afs.collection<Batch>('batches');
    }

 
  ngOnInit(): void {
  }

  aleartTimeOut(time: number) {
    setTimeout(() => {
      this.errors = false;
      this.loading = false;
    }, time)
  }


  
  publishData(emails: string[], created_by: string, total_emails: number) {    
    const batch_id = this.afs.createId();
    const created_at = new Date(); 
    const batch: Batch = {batch_id, created_at, created_by, emails, total_emails};
    this.itemsCollection.doc(batch_id).set(batch); 
    // this.clvApiService.postEmails(batch_id, emails, 'high').subscribe({
    //   next: data => {
    //     this.router.navigate(['/results', batch_id]);
    //   },
    //   error: error => {
    //     this.itemsCollection.doc(batch_id).delete();
    //     this.errors = 'There was an error!', error;
    //     this.loading = false;
    //     this.aleartTimeOut(5000);

    //   }
    // }) 
    this.clvApiService.parentTextformComponent = this;
    this.clvApiService.postEmails(batch_id, emails, 'high', 'app-textform')
  } 

  publishedSuccess(batch_id){
    this.router.navigate(['/results', batch_id]);
  }


  errorPublishingData(batch_id, error){
    this.itemsCollection.doc(batch_id).delete();
    this.errors = 'There was an error!', error;
    this.loading = false;
    this.aleartTimeOut(5000);
  }

  

  

  getEmailList(data: string) {    
    const emails: string[] = []; 
    const splited_list = data.split('\n');
    for (var email of splited_list) {
        if( email.length <= 5 || (email.includes('.') === false || email.includes('@') === false)) {
          console.log("invalid email type: ", email);
        } else {
          if (!emails.includes(email)) { 
              emails.push(email);
          } else {
            console.log("Duplicate email: ", email);
          };
        };
    }
    return emails;
  }

  handleUserForm(data: string): void { 
    this.loading = true;
    if (data != '') {
      const emails = this.getEmailList(data);
      if (emails.length <= 9) {
        this.errors = 'Sorry! Please add minimum 10 unique email addresses.';
        this.loading = false;
        this.aleartTimeOut(5000);
      }

      else if (emails.length > 1000) {
        this.errors = 'Sorry! Please add maximum 1000 unique email addresses only.';
        this.loading = false;
        this.aleartTimeOut(5000);
      }

      else {
        const total_emails = emails.length;
        const created_by = this.googleUserID;
        this.publishData(emails, created_by, total_emails);          
        
      }
    } 
    else {
      this.errors = 'Sorry! Please enter atleast one email address';
      this.loading = false;
      this.aleartTimeOut(5000);
    }
  }

}
