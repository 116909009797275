
<div class="row mt-5">
    <div class="col d-flex justify-content-between">
        <h3 style="color: #3f729b;" class="mb-0">Low Volume</h3>
        <p class="mb-0">
            In this mode, you can test upto 10 email addresses.
        </p>
      
    </div>
    
</div>
<hr class="mt-1">


<div>

    <div class="row">
        <div class="col-5  text-center">

            <div class="border border-light p-5 z-depth-1" style="background: white;">

                <p class="h4 mb-4"><strong style="color: #3f729b;">Copy and paste list of contacts here</strong></p>

                <!-- Message -->
                <div class="form-group">
                    <textarea #userForm class="form-control rounded-0" rows="8" placeholder="example_email1@work-email.com 
example_email2@work-email.com 
example_email3@work-email.com 


Hint: One email per line" name="textarea"></textarea>
                </div>
                <!-- Send button -->
                <ng-container *ngIf="loading else notLoadingButton">

                    <button disabled mdbBtn color="dark" mdbWavesEffect>Running Checks...</button>
                </ng-container>
                <ng-template #notLoadingButton>
                    <button mdbBtn color="dark" mdbWavesEffect (click)="handleUserForm(userForm.value);"
                        (click)="userForm.value=''">Run Checks</button>
                </ng-template>

            </div>


        </div>








        <div class="col-7">

            <!-- <h5>Results</h5> -->

            <ng-container *ngIf="totalEmails >= 1 else loadingResult">
                <mdb-card class="p-2 pl-3 mt-1" *ngFor="let item of emailItems | async">
                    <div class="row d-flex justify-content-between">
                        <div class="col" style="float: left; padding-left: 20px;">
                            <p class="mb-0"><i class="fas fa-envelope"></i>&nbsp; &nbsp; {{item.email}}</p>
                        </div>
                        <!-- - {{item.serial_number}}   -->

                        <div class="col d-flex justify-content-end" style="text-align: right; margin-right: 20px;">

                            <p class="text-warning mb-0 mx-2 select-icon" 
                            *ngIf="(item.status == 'Valid-But-Not-Deliverable') || (item.status == 'Risky')"
                            mdbTooltip="{{getStatusHelpValue(item.status)}}" placement="bottom"
                            ><i class="fas fa-exclamation-circle"></i></p>

                                <div class="mx-2">

                                    <app-status-badge [status]="item.status"></app-status-badge>
                                    

                                </div>

                                <p class="text-muted mb-0 ml-2 select-icon"
                                mdbTooltip="Go to pattern finder" placement="bottom" (click)="goToSuggestion(item.email)"
                                ><i class="far fa-eye"></i></p>

                        </div>


                    </div>
                </mdb-card>

            </ng-container>

            <ng-template #loadingResult>
                <div class="row" style="padding-top: 100px">
                    <div class="col">
                        <svg style="display: block; margin: auto" xmlns="http://www.w3.org/2000/svg" width="100"
                            height="100" viewBox="0 0 166.606 166.606">
                            <path id="Icon_material-error-outline" data-name="Icon material-error-outline"
                                d="M77.973,111.294H94.633v16.661H77.973Zm0-66.642H94.633V94.633H77.973ZM86.22,3a83.3,83.3,0,1,0,83.386,83.3A83.262,83.262,0,0,0,86.22,3ZM86.3,152.945A66.642,66.642,0,1,1,152.945,86.3,66.624,66.624,0,0,1,86.3,152.945Z"
                                transform="translate(-3 -3)" fill="#c1011a"></path>
                        </svg>
                        <h1 class="text-center">No Results so far!</h1>
                    </div>
                </div>
            </ng-template>

        </div>





    </div>

</div>


<div class="row mt-5">
    <div class="col-12 text-center">
        <ng-container *ngIf="batch else noBatch"></ng-container>
        <p> Your last test was at {{(batch | async)?.created_at.seconds * 1000 | date: 'medium'}}</p>
        <ng-template #noBatch>
        </ng-template>
    </div>
</div>