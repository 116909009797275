

    <div class="row">

        <div class="col"></div>

        <div class="col" style="margin-top: 100px;"> 

        <mdb-card class="card p-4"> 
 
    <div class="center p-2"><img class="logo" src="assets/images/logo-mini.png" ></div>
    <div class="center p-2"><h3>Contact List Verifier</h3></div>
    <div class="center p-1"><button (click)="login()" type="button" color="dark" mdbBtn mdbWavesEffect>Login</button></div>
    <div class="center p-2"><h6 style="color: gray;">&copy; Buyer Foresight 2021-2021</h6></div>

    <div class="center"><p><a href="#">Terms & Conditions</a></p></div>
                
            
        </mdb-card>
    


        </div>


        <div class="col"></div>
    </div> 