export interface Batch {
    batch_id: string;   
    created_at: Date;
    created_by: string; 
    emails: string[],
    total_emails: number;
}


export interface Email {
    batch_id: string;
    email: string;
    serial_number: number;
    status: string
}


export class CSVFileFormat {
    "email": string
    "first_name": string
}

export class DownloadFormat {

    "batch_id": string
    "created_at": Date
    "email": string
    "first_name": string
    "serial_number": number
    "server_code": number
    "server_message": string

}



 