import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-leaderboard',
  templateUrl: './user-leaderboard.component.html',
  styleUrls: ['./user-leaderboard.component.scss']
})
export class UserLeaderboardComponent implements OnInit {
  ratingValue: number = 2.56;
  starData: { rating: number, filled: boolean }[] = [
    { rating: 1, filled: false },
    { rating: 2, filled: false },
    { rating: 3, filled: false },
    { rating: 4, filled: false },
    { rating: 5, filled: false }
  ];
  constructor() { }

  ngOnInit(): void {
    this.setRatingStar();
  }
  setRatingStar(): void {
    this.starData.forEach(star => {
      star.filled = star.rating <= Math.round(this.ratingValue);
    });
  }

  onStarClick(rating: number): void {
    this.ratingValue = rating;
    this.setRatingStar();
  }

}
