<div class="row pb-4">

    <div class="container-fluid">
        <div class="card z-depth-1 mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold brand-color">Top Users</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email Verified</th>
                                <th>Email Found</th>
                                <th>Email Pattern</th>
                                <th>Point/Rating</th>
                            </tr>
                        </thead>
                        <!-- <tfoot>
                            <tr>
                                <th>Name</th>
                                <th>Email Verified</th>
                                <th>Email Found</th>
                                <th>Email Pattern</th>
                                <th>Point/Rating</th>
                            </tr>
                        </tfoot> -->
                        <tbody>
                            <tr>
                                <td>
                                    <img alt="User" src="https://phuoc.ng/assets/avatar.png" class="avatar avatar-sm rounded-circle me-2">
                                    <a class="brand-color" href="#">
                                        Sumit Kumar
                                    </a>
                                </td>
                                <td>55</td>
                                <td>23</td>
                                <td>3</td>
                                <td>
                                    <div class="star-rating">
                                        <span *ngFor="let star of starData" class="fa" [class.fa-star]="star.filled"
                                            [class.fa-star-o]="!star.filled" (click)="onStarClick(star.rating)"></span>
                                        <input type="hidden" name="rating-value" class="rating-value"
                                            [value]="ratingValue">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img alt="User" src="https://phuoc.ng/assets/avatar.png" class="avatar avatar-sm rounded-circle me-2">
                                    <a class="brand-color" href="#">
                                        Manish Mahadevan
                                    </a>
                                </td>
                                <td>75</td>
                                <td>43</td>
                                <td>3</td>
                                <td>
                                    <div class="star-rating">
                                        <span *ngFor="let star of starData" class="fa" [class.fa-star]="star.filled"
                                            [class.fa-star-o]="!star.filled" (click)="onStarClick(star.rating)"></span>
                                        <input type="hidden" name="rating-value" class="rating-value"
                                            [value]="ratingValue">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img alt="User" src="https://phuoc.ng/assets/avatar.png" class="avatar avatar-sm rounded-circle me-2">
                                    <a class="brand-color" href="#">
                                        Chandan Dandapat
                                    </a>
                                </td>
                                <td>65</td>
                                <td>13</td>
                                <td>7</td>
                                <td>
                                    <div class="star-rating">
                                        <span *ngFor="let star of starData" class="fa" [class.fa-star]="star.filled"
                                            [class.fa-star-o]="!star.filled" (click)="onStarClick(star.rating)"></span>
                                        <input type="hidden" name="rating-value" class="rating-value"
                                            [value]="ratingValue">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img alt="User" src="https://phuoc.ng/assets/avatar.png" class="avatar avatar-sm rounded-circle me-2">
                                    <a class="brand-color" href="#">
                                        Kamlesh Mishra
                                    </a>
                                </td>
                                <td>25</td>
                                <td>13</td>
                                <td>4</td>
                                <td>
                                    <div class="star-rating">
                                        <span *ngFor="let star of starData" class="fa" [class.fa-star]="star.filled"
                                            [class.fa-star-o]="!star.filled" (click)="onStarClick(star.rating)"></span>
                                        <input type="hidden" name="rating-value" class="rating-value"
                                            [value]="ratingValue">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img alt="User" src="https://phuoc.ng/assets/avatar.png" class="avatar avatar-sm rounded-circle me-2">
                                    <a class="brand-color" href="#">
                                        Sayantan Sadhu
                                    </a>
                                </td>
                                <td>85</td>
                                <td>33</td>
                                <td>8</td>
                                <td>
                                    <div class="star-rating">
                                        <span *ngFor="let star of starData" class="fa" [class.fa-star]="star.filled"
                                            [class.fa-star-o]="!star.filled" (click)="onStarClick(star.rating)"></span>
                                        <input type="hidden" name="rating-value" class="rating-value"
                                            [value]="ratingValue">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>