import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiServiceDelegate } from './api-service-delegate';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiService {

  backend_service = environment.clv_backend_service;

  constructor(private http: HttpClient) { }

  // this method is provided to client classes to call api
  callService(data: ApiServiceDelegate) {
    var newURL = this.backend_service + String(data.Action);
    this.execute(data.Data, newURL, data.Method).subscribe(
      (result: any) => {
        let res = result.res;
        data.Success(res);
      },
      (error: any) => {
        if (typeof error['Errors'] !== 'undefined') {
          data.Error(error['Errors']);
        }
      }
    );
  }

  execute(apiData: any, url: string, method: String) {
    if (method == 'POST') {
      return this.http.post(url, apiData, this.getHeaderOptions()).pipe(map((res: any) => { return { res: res }; })).pipe(catchError(this.errorHandler));
    } else if (method == 'DELETE') {
      return this.http.delete(url, this.getHeaderOptions()).pipe(map((res: any) => { return { res: res }; })).pipe(catchError(this.errorHandler));
    } else if (method == 'PATCH') {
      return this.http.patch(url, apiData, this.getHeaderOptions()).pipe(map((res: any) => { return { res: res }; })).pipe(catchError(this.errorHandler));
    } else if (method == 'PUT') {
      return this.http.put(url, apiData, this.getHeaderOptions()).pipe(map((res: any) => { return { res: res }; })).pipe(catchError(this.errorHandler));
    } else {
      return this.http.get(url, this.getHeaderOptions()).pipe(map((res: any) => { return { res: res }; })).pipe(catchError(this.errorHandler));
    }
  }

  getHeaderOptions() {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json', );
    httpHeaders = httpHeaders.set('Access-Control-Allow-Origin', '*' );
    // const loggedObject: any= AppCommon.getloggedInObject();
    // const token: any = loggedObject?.token;
    // if (loggedObject && token){
    //   httpHeaders = httpHeaders.set("Authorization", "Bearer " + token);
    // } 
    const httpOptions = {
      headers: httpHeaders
    };
    return httpOptions;
  }

  errorHandler(e: any) {
    return throwError({ Errors: e.error });
  }




}