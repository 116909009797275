<div class="container-fluid ">
    <div class="row mt-5">
        <div class="col">
            <div class="d-flex justify-content-center">
                <!-- <h4 class="header-text">Pattern Finder:</h4> -->
                <p class="mx-2">
                    Use this feature to find probable email address patterns for a given domain.
                </p>
            </div>

            <hr class="mt-0">
        </div>
    </div>








    <div class="row d-flex justify-content-start">

        <div class="col-12">

            <div class="row">
                <div class="col-5">
                    <form [formGroup]="domainForm" (ngSubmit)="onSubmit()" class="d-flex w-100">
                        <div class="form-group w-100 mt-1">
                            <input type="text" class="form-control shadow-sm" style="font-size: 14px;" placeholder="Provide work email domain..."
                                formControlName="domain">
                            <div class="text-danger small mt-1"
                                *ngIf="domainForm.get('domain').invalid && domainForm.get('domain').touched">
                                <span *ngIf="domainForm.get('domain').errors.required">Domain is
                                    required.</span>
                                <span *ngIf="domainForm.get('domain').errors.invalidDomain">Domain is not
                                    valid.</span>
                            </div>
                        </div>
                        <div >
                            <button type="submit" mdbBtn color="dark" size="sm">Search</button>
                        </div>
                    </form>




                </div>
                <div class="col-7 d-flex justify-content-end">

                    <div class="m-2" *ngIf="selectedFilter.length > 0">
                        <span class="badge rounded-pill text-white" (click)="clearFilter()"
                            style="background-color: rgb(61, 99, 190); cursor: pointer;">{{selectedFilter}} 
                            <span style="margin-left: 5px;"><i
                                    class="far fa-times-circle"></i></span></span>
                    </div>

                    <div class="m-2">
                        <span class="badge rounded-pill text-white" style="background-color: grey;">{{emailList.length}}
                            Results</span>
                    </div>


                </div>

            </div>

        </div>

    </div>


    <div class="row d-flex justify-content-start">
        <div class="col-5">
            <div class="card shadow-sm" >
                <div class="card-header border-bottom" style="background-color: #e8f5ff; border: none;">
                    <h6 class="card-title mb-1 mt-1" style="color: #3f729b !important;">
                        <i class="fas fa-flag-checkered"></i>
                        Historical successful deliveries
                    </h6>
                </div>
                <div class="card-body p-0">

                    <div *ngIf="isLoading" class="p-3">
                        <div class="d-flex">
                            <div class="mt-1">
                                <div class="spinner-border text-dark" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <p class="p-2"> Fetching data...</p>
                        </div>
                    </div>



                    <div *ngIf="!isLoading">
                        <div class="alert alert-warning m-3" role="alert" *ngIf="showNoResults">
                            <i class="fas fa-exclamation-triangle"></i> Oops! We couldn't find any relevant information.
                        </div>

                        <div class="alert alert-danger m-3" role="alert" *ngIf="showApiError">
                            <i class="fas fa-exclamation-triangle"></i> Oops! Error while processing your request, please inform tech team about this domain.
                        </div>

                        <p *ngIf="dataList.length == 0 && !showNoResults && !showApiError" class="card-text p-3">No result available yet.</p>

                        <ul class="list-group list-group-flush pa-list-area" *ngIf="dataList.length > 0">
                            <li class="list-group-item d-flex justify-content-between align-items-center "
                                *ngFor="let item of dataList">
                                <p class="mb-0">{{item.key}}</p>
                                <p class="mb-0"><a href="javascript:void(0)" (click)="filterEmail(item.key)">{{item.value?.length}} emails</a></p>
                            </li>
                        </ul>


                    </div>


                </div>
            </div>
        </div>


        <div class="col-7">

            <div class="text-center p-2" *ngIf="emailList.length == 0">
                <img class="no-result-image" src="assets/images/empty_data_tree.svg" >
                <h3>Try refining your search</h3>
                <h5 class="m-3 px-2">No results found. </h5>
            </div>

            <ul class="list-group result-area"  >
                <li class="list-group-item d-flex justify-content-between align-items-center mb-3 shadow-sm"
                    *ngFor="let item of emailList">
                    <div>
                        <p class="mb-0 text-capitalize"><i class="far fa-user"></i> {{item.first_name}} {{item.middle_name}}
                            {{item.last_name}}</p>
                        <p class="mb-0"><i class="far fa-envelope"></i> {{item.email}}

                        </p>
                    </div>
                    <div>
                        <p class="mb-0" style="font-size: 12px; color: grey;">Last Validated on {{getDate(item.last_contacted)}}
                        </p>
                        <div class="d-flex justify-content-end">
                            <span class="mt-1">
                                <mdb-badge [pill]="true" [success]="true"><i class="fas fa-check-double"></i>
                                    {{getPercentage(item)}}%</mdb-badge>
                            </span>
                            <span>
                                <button (click)="copy(item.email)" type="button" mdbBtn color="secondary" size="sm"
                                    class="copy-btn" mdbWavesEffect><i class="far fa-copy"></i></button>
                            </span>
                        </div>
                    </div>

                </li>
            </ul>
        </div>

    </div>



</div>