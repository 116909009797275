
<!-- 1st Row sticky -->
<div class="row z-depth-1" style="background-color: white; 
 position: fixed; right: 0; width: 100%; margin:0; margin-top: -2px;
 z-index: 1;">

    <div class="col-2">
        <ul class="nav justify-content-start">
            <li class="nav-item">
                <a class="nav-link active allResultsButton" aria-current="page" [routerLink]=""
                    (click)="back()"><mdb-icon fas icon="hand-point-left"></mdb-icon> Go Back</a>
            </li>
        </ul>
    </div>
    <div class="col-8">
        <h6 class="text-center" style="margin-bottom: 0rem; padding-top:10px;">
            {{batchId | uppercase}} - {{(batch | async)?.created_at.seconds * 1000 | date: 'medium'}}</h6>
    </div>
    <div class="col-2">
        <ul class="nav justify-content-end">
            <li class="nav-item">
                <a class="nav-link active deleteResultButton" aria-current="page" [routerLink]=""
                    (click)="basicModal.show()">Delete <mdb-icon fas icon="trash-alt"></mdb-icon></a>
            </li>
        </ul>

    </div>

</div>
<!-- 1st Row end-->



<div class="row" style="padding-top: 3.5%;">
    <!-- 1st Col sticky -->
    <div class="col-4 " style="position: fixed; left: 0; margin-left: 25px;">




        <div class="row mt-1">

            <div class="col-12"> 

                <mdb-card>
                    <mdb-card-body style="text-align: center; padding: 0rem;">
                        <div class="m-0" *ngIf="(progressPercentage != 0) && (progressPercentage != 100)">
                            <div class="progress processing-bar-wrapper" style="height: 25px;">
                                <div class="progress-bar processing-bar" 
                                role="progressbar"
                                [style.width]="(progressPercentage + '%')" 
                                [attr.aria-valuenow]="progressPercentage"
                                aria-valuemin="0"
                                aria-valuemax="100">{{progressPercentage == 100 ? 'Completed': 'Processing ' + progressPercentage + '%'}}</div> 
                            </div> 
                        </div>

                        <h1 class="resultDataH6Box pt-2">
                            {{totalTestedCount}}/{{(batch | async)?.total_emails}}
                        </h1>

                        

                        <p (click)="download()" class="resultDataPBox"><mdb-icon fas icon="download"></mdb-icon>
                            <strong> Total All</strong>
                        </p>


                    </mdb-card-body>
                </mdb-card>
            </div>

            <div class="col-12 mt-3">

                <mdb-card>
                    <mdb-card-body style="text-align: center; padding: 0rem; ">


                        <h1 class="resultDataH6Box pt-2">
                            {{deliverableCount}}
                        </h1>
                        <p (click)="download('deliverable')" class="resultDataPBox"><mdb-icon fas
                                icon="download"></mdb-icon>
                            <strong> Deliverable</strong>
                        </p>

                    </mdb-card-body>
                </mdb-card>
            </div>
        </div>


        <div class="row mt-3">
            <div class="col-12">
                <mdb-card>
                    <mdb-card-body style="text-align: center; padding: 0rem; ">



                        <h1 class="resultDataH6Box pt-2">
                            {{undeliverableCount}}
                        </h1>
                        <p (click)="download('undeliverable')" class="resultDataPBox"><mdb-icon fas
                                icon="download"></mdb-icon>
                            <strong> Undeliverable</strong>
                        </p>

                    </mdb-card-body>
                </mdb-card>
            </div>

            <div class="col-12 mt-3">
                <mdb-card>
                    <mdb-card-body style="text-align: center; padding: 0rem; ">



                        <h1 class="resultDataH6Box pt-2">
                            {{riskyCount}}
                        </h1>
                        <p (click)="download('risky')" class="resultDataPBox"><mdb-icon fas icon="download"></mdb-icon>
                            <strong> Risky</strong>
                        </p>

                    </mdb-card-body>
                </mdb-card>
            </div>


        </div>



    </div>
    <!-- 1st Col end-->

    <!-- 2nd Col -->
    <div class="col-8" style="margin-left: 33.5%; margin-bottom: 50px;">
        <ng-container *ngIf="totalTestedCount >= 1 else loading">
            <mdb-card class="p-1 pl-3 mt-1" *ngFor="let item of items | async">
                <div class="row d-flex justify-content-between">
                    <div class="col" style="float: left; padding-left: 20px;">
                        <i class="fas fa-envelope"></i>&nbsp; &nbsp; {{item.email}}
                    </div>
                    <!-- - {{item.serial_number}}   -->

                    <div class="col d-flex justify-content-end" style="text-align: right; margin-right: 20px;">
                        

                        <p class="text-warning mb-0 mx-2 select-icon" 
                        *ngIf="(item.status == 'Valid-But-Not-Deliverable') || (item.status == 'Risky')"
                        mdbTooltip="{{getStatusHelpValue(item.status)}}" placement="bottom"
                        ><i class="fas fa-exclamation-circle"></i></p>

                            <div class="mx-2">

                                <app-status-badge [status]="item.status"></app-status-badge>

                            </div>

                            <p class="text-muted mb-0 ml-2 select-icon"
                            mdbTooltip="Go to pattern finder" placement="bottom" (click)="goToSuggestion(item.email)"
                            ><i class="far fa-eye"></i></p>
                            
                    </div>

                </div>
            </mdb-card>

        </ng-container>
        <ng-template #loading>
            <div class="row">
                <div class="col">
                    <h1 class="text-center"><mdb-icon fas icon="spinner" class="fa-spin fa-1x"></mdb-icon> Please wait,
                        checking...</h1>
                </div>
            </div>
        </ng-template>
        ​
    </div>
    <!-- 2nd Col end-->
</div>





<!-- Modal -->

<div mdbModal style="padding-top: 100px; " #basicModal="mdbModal" class="modal fade mt-5" tabindex="-1" role="dialog"
    aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">Delete Results</h4>
            </div>
            <div class="modal-body">
                <h3>This action is permanent!</h3>
                <h6>{{batchId}} will be deleted.</h6>
                <h6>Are you sure want to delete this batch?</h6>
            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="secondary" class="btn btn-outline-grey waves-effect"
                    aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" class="relative btn btn-outline-danger waves-effect"
                    (click)="deleteBatch(batchId)" mdbWavesEffect>Delete</button>
            </div>
        </div>
    </div>
</div>

<!-- Model -->