import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { LoginService } from '../services/login.service';
import { Batch } from '../models/dataModel'; 
// import { Email } from '../dataModel'
import { Observable } from 'rxjs';
import {NgForm} from '@angular/forms';
import { ClvApiService } from '../services/clv-api.service';
import { Router } from '@angular/router';
import { AppCommon } from '../common/app-common';

@Component({
  selector: 'app-low-volume',
  templateUrl: './low-volume.component.html',
  styleUrls: ['./low-volume.component.scss']
})
export class LowVolumeComponent implements OnInit {
 
  googleUserID: any;
  errors: any;
  private itemsCollection: AngularFirestoreCollection<Batch>; 
  batch: Observable<any>;
  emailItems: Observable<any>;
  loading: boolean = false;
  loadingResult: boolean = false;
  templateItems: any = [];
  totalEmails = 0;
 

  constructor(
    private clvApiService: ClvApiService,
    private readonly afs: AngularFirestore,
    private router: Router
    ) {
    this.googleUserID = String(AppCommon.getUserId()); 
    this.itemsCollection = this.afs.collection<Batch>('smallBatches');
    this.batch = this.itemsCollection.doc(this.googleUserID).valueChanges();   
    const userTestLocation = 'smallBatches/' + this.googleUserID + '/emails';
    this.emailItems = this.afs.collection(userTestLocation, ref =>       
      ref.orderBy('created_at', 'desc').limit(10)).valueChanges();

    this.emailItems.subscribe({
      next: data => {
        this.totalEmails = data.length;
      },
      error: error => {

      }
    });


    }

   

  ngOnInit(): void {
   
    
  } 
  
  aleartTimeOut(time: number) {
    setTimeout(() => {
      this.errors = false;
      this.loading = false;
    }, time)
  }
  
  publishData(emails: string[], created_by: string, total_emails: number) {  
    
    
    const batch_id = created_by;
    const created_at = new Date(); 
    const batch: Batch = {batch_id, created_at, created_by, emails, total_emails};
    this.itemsCollection.doc(batch_id).set(batch); 
    // this.clvApiService.postEmails(batch_id, emails, 'low').subscribe({
    //   next: data => {
    //     this.aleartTimeOut(2000);
    //   },
    //   error: error => { 
    //     this.errors = 'There was an error!', error;
    //     this.loading = false;
    //     this.aleartTimeOut(3000);

    //   }
    // })

    this.clvApiService.parentLowVolumeComponent = this;
    this.clvApiService.postEmails(batch_id, emails, 'low', 'app-low-volume');

  } 

  publishedSuccess(batch_id){
    this.aleartTimeOut(2000);
  }


  errorPublishingData(batch_id, error){
    this.errors = 'There was an error!', error;
    this.loading = false;
    this.aleartTimeOut(3000);
  }

  getStatusHelpValue(status){
    var message = ""
    if(status === 'Risky'){
      message = "Recipient mail server declined to confirm at this moment."
    }
    if(status === 'Valid-But-Not-Deliverable'){
      message = "Email is correct but mailbox is full"
    }
    return message
  }


  goToSuggestion(company_domain) {
    const domain = company_domain.split("@")[1]
    const url = `/lookup?domain=${domain}`;
    this.router.navigateByUrl(url)
  }

  getEmailList(data: string) {    
    const emails: string[] = []; 
    const splited_list = data.split('\n');
    for (var email of splited_list) {
        if( email.length <= 5 || (email.includes('.') === false || email.includes('@') === false)) {
          console.log("invalid email type: ", email);
        } else {
          if (!emails.includes(email)) { 
              emails.push(email);
          } else {
            console.log("Duplicate email: ", email);
          };
        };
    }
    return emails;
  }
 
  handleUserForm(data: any): void {
    this.loading = true;
    if (data.value != '') {
      const emails = this.getEmailList(data);
      if (emails.length <= 0) {
        this.errors = 'Sorry! Please add minimum 1 email address.';
        this.loading = false;
        this.aleartTimeOut(5000);
      }

      else if (emails.length >= 11) {
        this.errors = 'Sorry! Please add maximum 10 emails only.';
        this.loading = false;
        this.aleartTimeOut(5000);
      }

      else {
        const total_emails = emails.length;
        const created_by = this.googleUserID;
        this.publishData(emails, created_by, total_emails);          
        
      }
    } 
    else {
      this.errors = 'Sorry! Please enter atleast one email address';
      this.loading = false;
      this.aleartTimeOut(5000);
    }
  }


}
