 

<div class="row pt-3"> 
    <div class="col-12 text-center">

 <div class="border border-light p-5 z-depth-1" style="background: white;"> 

    <p class="h4 mb-4"><strong style="color: #3f729b;">Copy and paste list of contacts here</strong></p> 
  
    <!-- Message -->
    <div class="form-group">
      <textarea #userForm class="form-control rounded-0" rows="6" placeholder="example_email1@work-email.com 
example_email2@work-email.com 
example_email3@work-email.com 
      
      
Hint: One email per line" name="textarea"></textarea>
    </div>
 
    <!-- Send button -->
    <button mdbBtn color="dark"  mdbWavesEffect (click)="handleUserForm(userForm.value);">Run Checks</button> 

  
  </div>
  <!-- Default form contact --> 
 
  <div class="row" >
    <div class="col-12 mt-1" *ngIf="errors">
      <div class="alert alert-danger z-depth-1" role="alert">
       {{errors}}
      </div>
    </div>
  
    <div class="col-12 mt-1" *ngIf="loading">
      <div class="alert alert-info z-depth-1" role="alert">
        <mdb-icon fas icon="spinner" class="fa-spin fa-1x"></mdb-icon> Please wait, checking the data...
      </div>
    </div> 
  </div> 
    

</div>





