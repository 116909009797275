<!--Navbar-->


<mdb-navbar *ngIf="isLoggedin === true" SideClass="navbar navbar-expand-lg navbar-dark fixed-top pl-5 pr-5 unique-color"
  [containerInside]="false">

  <!-- Navbar brand -->
  <!-- <mdb-navbar-brand><a class="navbar-brand" href="/"><img [src]="logoSrc" height="30" alt=""></a></mdb-navbar-brand>
    <mdb-navbar-brand><a class="navbar-brand" href="/">Contact List Verifier</a></mdb-navbar-brand> -->

  <mdb-navbar-brand mdbTooltip="Contact List Verifier" placement="bottom"><a class="navbar-brand" href="/">
      <div class="logo"> <i class="fas fa-podcast"></i> CLV</div>
    </a></mdb-navbar-brand>

  <!-- Collapsible content -->
  <links>

    <!-- Links -->
    <ul class="navbar-nav mr-auto">

      <li class="nav-item mx-2" routerLink="/finder" [routerLinkActive]="'active'">
        <a class="nav-link waves-light" mdbWavesEffect>
          <i class="fas fa-magic"></i> Email Finder
        </a>
      </li>

      <li class="nav-item mx-2" routerLink="/lookup" [routerLinkActive]="'active'">
        <a class="nav-link waves-light" mdbWavesEffect>
          <i class="far fa-eye"></i> Pattern Finder
        </a>
      </li>

      <li class="nav-item mx-2" routerLink="/low-volume" [routerLinkActive]="'active'">
        <a class="nav-link waves-light" mdbWavesEffect>
          <i class="fas fa-compress"></i> Verifier (Low)
        </a>
      </li>

      <li class="nav-item mx-2" routerLink="/high-volume" [routerLinkActive]="'active'">
        <a class="nav-link waves-light" mdbWavesEffect>
          <i class="fas fa-expand-arrows-alt"></i> Verifier (High)
        </a>
      </li>


      <!-- <li class="nav-item dropdown mx-2" dropdown [routerLinkActive]="'active'">
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          <i class="fas fa-user-check"></i> Verifier</a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a routerLink="/low-volume" class="dropdown-item waves-light" mdbWavesEffect>Low Volume</a>

          <a routerLink="/high-volume" class="dropdown-item waves-light" mdbWavesEffect>High Volume</a>
          <div class="divider dropdown-divider"></div>
          <a routerLink="/results" class="dropdown-item waves-light" mdbWavesEffect>Test Results</a>
        </div>
      </li> -->
  
      <li class="nav-item mx-2" routerLink="/results" [routerLinkActive]="'active'">
        <a class="nav-link waves-light" mdbWavesEffect>
          <i class="fas fa-file-invoice"></i> Test Results
        </a>
      </li>

    </ul>
    <!-- Links -->



    <ul class="navbar-nav ml-auto nav-flex-icons">
      
   

      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          <img src="../../../assets/images/youtube.png" alt="" height="15" width="auto"> Videos
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect *ngFor="let video of youtube_videos; let index=index;"
          href="javascript:void(0)" (click)="openYoutubeModal(video)"> 
          {{index+1}}. {{video.name}}
          </a> 
        </div>
      </li>
      

      <!-- <li class="nav-item">
        <a href="https://apps.buyerforesight.net" target="_blank" class="nav-link waves-light" mdbWavesEffect><mdb-icon
            fas icon="rocket"></mdb-icon> BF Applications</a>
      </li> -->

      <!-- <li class="nav-item">
          <a routerLink="/dashboard" class="nav-link waves-light" mdbWavesEffect>Dashboard</a>
        </li> -->



      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>

          <ng-container *ngIf="loggedinUser?.photoUrl else noPhoto">
            <img src="{{loggedinUser.photoUrl}}" class="shadow" alt="" width="25" height="25" style="border-radius: 5px;">
          </ng-container>

          <ng-template #noPhoto>
            <mdb-icon fas icon="user"></mdb-icon>
          </ng-template>
          
          
          {{loggedinUser.firstName}}<span class="caret"></span>
        </a>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
          <!-- <a class="dropdown-item waves-light" mdbWavesEffect  routerLink="/help">Help</a> -->
          <a class="dropdown-item waves-light" mdbWavesEffect target="_blank"
            href="https://support.buyerforesight.net/index.php?a=add&catid=3">Support</a>
          <!-- <a class="dropdown-item waves-light" mdbWavesEffect  routerLink="/feedback">Feedback</a> -->
          <div class="divider dropdown-divider"></div>
          <a class="dropdown-item waves-light" mdbWavesEffect (click)="logout()">Log out</a>
        </div>
      </li>

    </ul>
  </links>
  <!-- Collapsible content -->

</mdb-navbar>
<!--/.Navbar-->