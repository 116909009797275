
<ng-container *ngIf="totalItems != 0; else noData">
<div class="row p-3 mb-5">
 <div class="col-2"></div>
 <div class="col-8">
  <mdb-card style="width: 100%;">
    <table mdbTable  class="table table-striped table-bordered table-sm">
       <thead style="color: #3f729b;">
           <tr>
             <th scope="col"><strong>Results</strong> (ID - Created Date & Time)</th>
             <th scope="col" style="text-align: center;"><strong>Total Emails</strong></th>
             <th scope="col" colspan="2" style="text-align: center;"><strong>Action</strong></th>
           </tr>
         </thead>
         <tbody>
           <tr *ngFor="let item of items | async" > 
             <td (click)="getBatch(item.batch_id)" class="resultBatchID">{{item.batch_id | uppercase}} - {{item.created_at.seconds * 1000 | date: 'medium'}}</td>
             <td style="text-align: center;">{{item.total_emails}}</td>
       
 
            <td class="batchActionIcon" (click)="exportBatch(item.batch_id)" style="text-align: center;"><i style="color: #007E33;" class="fas fa-file-export fa-lg linkItem"></i></td>
             <td class="batchActionIcon" (click)="basicModal.show()" style="text-align: center;"><i style="color: #CC0000;" class="fas fa-trash-alt fa-lg linkItem"></i></td>
           
            
             <div mdbModal #basicModal="mdbModal" class="modal fade mt-5"  role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 class="modal-title w-100" id="myModalLabel">Delete Results</h4>
                  </div>
                  <div class="modal-body">
                    <h3>This action is permanent!</h3>
                    <h6>Are you sure want to delete this batch? </h6>
                  </div>
                  <div class="modal-footer">
                    <button type="button" mdbBtn color="secondary" class="btn btn-outline-grey waves-effect" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect>Close</button>
                    <button type="button" mdbBtn color="primary" class="relative btn btn-outline-danger waves-effect" (click)="deleteBatch(item.batch_id); basicModal.hide()"  mdbWavesEffect>Delete</button>
                  </div>
                </div>
              </div>
            </div>
           
            </tr>
         </tbody>
       
    </table>
  </mdb-card> 
 </div>
 <div class="col-2"></div>
</div>  
</ng-container>    

<ng-template #noData>  
  <div class="row p-3 mb-5">
    <div class="col-2"></div>

    <div class="col-8" *ngIf='noDataFound === true'>
      <mdb-card style="width: 100%;">
        <p class="pt-3 pl-3 pr-3">No Data Found, please do search...</p>
        </mdb-card>
    </div> 

    <div class="col-8" *ngIf='loading === true'>
      <mdb-card style="width: 100%;">
        <p class="pt-3 pl-3 pr-3"><mdb-icon fas icon="spinner" class="fa-spin fa-1x"></mdb-icon> Please wait, checking for the data...</p>
        </mdb-card>
    </div> 
      
    <div class="col-2"></div>
  </div> 

</ng-template>