<div class="row py-4">
    <div class="col text-center">
        <h3 style="color: #3f729b">Dashboard</h3>
        <hr />
    </div>
</div>

<app-dash-stat-card></app-dash-stat-card>

<app-user-leaderboard></app-user-leaderboard>

<app-info-card></app-info-card>