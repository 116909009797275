


<div class="row pt-3" >
    <div class="col-12 text-center">

        <div class=" border border-light z-depth-1 p-5" style="background: white;">

    
        
        <p class="h4 mb-2"><strong style="color: #3f729b;">Upload CSV file</strong></p> 

        <div style="padding-top: 5px;" class="mt-4">
          <p style="font-size: 15px;">The first row must contain column headers <strong>"email"</strong> and <strong>"first_name"</strong>.</p>
            <p> <strong>Samples: (<a href="../../assets/samples/sample.csv" download>CSV</a>)</strong></p>
         
        </div>


    <div class="center w-100">
            <input (change)="onFileSelected($event)"  class="form-control form-control-lg" #uploadFile class="uploadFile" accept=".csv, .txt" type="file"> 
    </div>
   
    <div style="padding-top: 20px;">
        <button  (click)="uploadEmailList()" mdbBtn class="mt-4" color="dark"  mdbWavesEffect type="submit">Upload and Run Checks</button>
    </div>   


    </div>

</div> 
        <div class="col-12 mt-1 text-center" *ngIf="errors">
            <div class="alert alert-danger z-depth-1" role="alert">
             {{errors}}
            </div>
          </div>
    
          <div class="col-12 mt-1 text-center" *ngIf="loading">
            <div class="alert alert-info z-depth-1" role="alert">
              <mdb-icon fas icon="spinner" class="fa-spin fa-1x"></mdb-icon> Please wait, checking the data...
            </div>
          </div>  

</div>


 