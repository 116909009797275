import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-arrow-steps',
  templateUrl: './arrow-steps.component.html',
  styleUrls: ['./arrow-steps.component.scss']
})
export class ArrowStepsComponent implements OnInit {

  @Input() menuList:string[] = []
  @Input() currentItem:string = undefined;

  constructor() { }

  ngOnInit(): void {
  }


  getFoundBy(){
    
  }

}
