<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{youtube_name}}</h4>
    </div>
    <div class="modal-body text-center" *ngIf="youtube_link">
            <iframe [src]="videoUrl" width="750" height="450" frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="modalRef.hide()"
            mdbWavesEffect>Close</button>
    </div>
</div>