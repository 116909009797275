import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { Router } from '@angular/router';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { ApiCollection } from '../common/api-collection';
import { EventEmitter } from 'stream';
import { BehaviorSubject } from 'rxjs';
import { AppCommon } from '../common/app-common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  public userLoginEvent = new BehaviorSubject<boolean>(false);
  public userLogoutEvent = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, 
    private socialAuthService: SocialAuthService, 
    private router: Router
    ) {
      
   }


  isLoggedIn(): boolean {
    const clv_user_data = AppCommon.getUserDataObject();
    const clv_user = AppCommon.getUserId();
    const user_data_updated = AppCommon.isUserDataUpdated();
    if(clv_user && clv_user_data && user_data_updated){
      return true;
    } else {
      return false
    }
    
  }


  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then( user => {
      if (user?.id) { 
        AppCommon.setUserId(user.id);
        AppCommon.setUserDataObject(user);
        this.updateUserDetails();
      } 
    }).catch( error => { 
        console.log("login failed", error);
    })
  };


  signOut(): void {
    this.socialAuthService.signOut();
    localStorage.clear();
    sessionStorage.clear();
    this.userLogoutEvent.next(true);
    this.router.navigate(['/login']);

  };
  
 
  updateUserDetails(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const data = AppCommon.getUserDataObject()
    const backend_service = environment.clv_backend_service;
    return this.http.post<any>(backend_service + ApiCollection.CLV_UPDATE_USER, data, { headers })
    .subscribe(
      (response) => {
        AppCommon.setUserUpdated(true);
        this.userLoginEvent.next(true);
        this.router.navigate(['/high-volume']);
        console.log('CLV user updated');
        // console.log('User POST request successful:', response);
      },
      (error) => {
        console.error('User POST request failed:', error);
      }
    );

  }
 

 

 

}
