<div class="wrapper">

    <ol class="ProgressBar">
        <li class="ProgressBar-step" [class.is-current]="item == currentItem" *ngFor="let item of menuList"
        mdbTooltip="{{'Verified by ' + item}}" placement="bottom">
            <svg class="ProgressBar-icon">
                <use xlink:href="#checkmark-bold" />
            </svg>
            <span class="ProgressBar-stepLabel">{{item}}</span>
        </li> 
    </ol>


    <div style="display: none;">
        <svg xmlns="http://www.w3.org/2000/svg">
            <symbol id="checkmark-bold" viewBox="0 0 24 24">
                <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
            </symbol>
        </svg>
    </div>

</div>