import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../app-routing.module';
import { ArrowStepsComponent } from './arrow-steps/arrow-steps.component';
import { StatusBadgeComponent } from './status-badge/status-badge.component';
import { YoutubePlayComponent } from './youtube-play/youtube-play.component';



@NgModule({
  declarations: [
    NavbarComponent,
    ArrowStepsComponent,
    StatusBadgeComponent,
    YoutubePlayComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
  ],
  exports: [
    NavbarComponent,
    ArrowStepsComponent,
    StatusBadgeComponent
  ]
})
export class ComponentsModule { }
