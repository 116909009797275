import { environment } from "src/environments/environment";


export class ApiCollection {
    static CLV_ADD_EMAILS = "/run/add-emails";
    static CLV_EGEN_MAKE_REQUEST =  "/finder/make-egen-request";
    static CLV_LOOKUP_BY_DOMAIN =  "/lookup/email-lookup-by-domain";
    static CLV_MX_TRACING = "/tools/validate-mx";
    static CLV_UPDATE_USER = "/users/update-user";
}
