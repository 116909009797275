import { Component, OnInit, Input} from '@angular/core';
import { Router } from '@angular/router'; 
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Batch, CSVFileFormat } from '../../models/dataModel' 
import { ClvApiService } from 'src/app/services/clv-api.service';

@Component({
  selector: 'app-uploadform',
  templateUrl: './uploadform.component.html',
  styleUrls: ['./uploadform.component.scss']
})
export class UploadformComponent implements OnInit {

  @Input() googleUserID: any;

  errors: any;
  loading: boolean = false;
  file:any;

  private itemsCollection: AngularFirestoreCollection<Batch>; 

  constructor(
    private clvApiService: ClvApiService, 
    private router: Router, 
    private readonly afs: AngularFirestore
  ) { this.itemsCollection = afs.collection<Batch>('batches'); }

  ngOnInit(): void {
    
  }

  aleartTimeOut(time: number) {
    setTimeout(() => {
      this.errors = false;
      this.loading = false;
    }, time)
  }

  publishData(records: any, created_by: string, total_emails: number) { 

    const batch_id = this.afs.createId();
    const created_at = new Date();
    const emails = JSON.parse(JSON.stringify(records))
    const batch: any = {batch_id, created_at, created_by, emails, total_emails};    
    this.itemsCollection.doc(batch_id).set(batch); 

    this.clvApiService.parentUploadformComponent = this;
    console.log(emails)
    this.clvApiService.postEmails(batch_id, emails, 'high-first-name', 'app-uploadform');

    // this.clvApiService.postEmails(batch_id, emails, 'high-first-name').subscribe({
    //   next: data => {
    //     this.router.navigate(['/results', batch_id]);
    //   },
    //   error: error => {
    //     console.log(error);
    //     this.itemsCollection.doc(batch_id).delete();
    //     this.errors = 'There was an error!', error;
    //     this.loading = false;
    //     this.aleartTimeOut(5000);

    //   }
    // }) 
        
  } 

  publishedSuccess(batch_id){
    this.router.navigate(['/results', batch_id]);
  }


  errorPublishingData(batch_id, error){
    this.itemsCollection.doc(batch_id).delete();
    this.errors = 'There was an error!', error;
    this.loading = false;
    this.aleartTimeOut(5000);
  }


  getEmailList(data: any) {
    const stringData = String(data);
    const splited_list = stringData.split('\r\n');
    const emails: string[] = [];     
    for (var email of splited_list) {
        if( email.length <= 5 || (email.includes('.') === false || email.includes('@') === false)) {
          console.log("invalid email type: ", email);
        } else {
          if (!emails.includes(email)) { 
              emails.push(email);
          } else {
            console.log("Duplicate email: ", email);
          };
        };
    }
    return emails;
  }

  

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = []; 
    for (let i = 1; i < csvRecordsArray.length; i++) { 
      let curruntRecord = (<string>csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        let csvRecord: CSVFileFormat = new CSVFileFormat();
        csvRecord.email = curruntRecord[0];
        csvRecord.first_name = curruntRecord[1];
        csvArr.push(csvRecord);
      }
     }
    return csvArr;
  }
 

  uploadEmailList(){
    this.loading = true;
    if (this.file){

      let reader = new FileReader();
      reader.readAsText(this.file);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        let headersRow = this.getHeaderArray(csvRecordsArray);
        var records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
        // console.log(records)

        if (records.length <= 9) {
          this.errors = 'Sorry! Please add minimum 10 unique email addresses.';
          this.loading = false;
          this.aleartTimeOut(5000);
        }

        else if (records.length > 1000) {
          this.errors = 'Sorry! Please add maximum 1000 unique email addresses only.';
        this.loading = false;
        this.aleartTimeOut(5000);
        }

        else if (records[0].first_name === undefined) {
        this.errors = 'Sorry! First name is required';
        this.loading = false;
        this.aleartTimeOut(5000);
        }

        else {
            const total_emails = records.length;
            const created_by = this.googleUserID;
            this.publishData(records, created_by, total_emails);  
        }
    };

      // let fileReader = new FileReader();
      // fileReader.onload = (e) => { 
        // const emails = this.getEmailList(fileReader.result);
        // if (emails.length <= 9) {
        //   this.errors = 'Sorry! Please add minimum 10 email addresses.';
        //   this.loading = false;
        //   this.aleartTimeOut(5000);
        // }
  
        // else if (emails.length >= 351) {
        //   this.errors = 'Sorry! Please add maximum 350 emails addresses only.';
        //   this.loading = false;
        //   this.aleartTimeOut(5000);
        // }
  
        // else {
        //   const total_emails = emails.length;
        //   const created_by = this.googleUserID;
        //   this.publishData(emails, created_by, total_emails);          
          
        // }
      // }
      // fileReader.readAsText(this.file);
    } else {
      this.errors = 'File is not loaded, please refresh and try again.';      
    }
    this.aleartTimeOut(5000);
  }

  onFileSelected(event: any){
    const file:File = event.target.files[0];
    if (file && (file.name.endsWith('csv') || file.name.endsWith('txt'))) {
      this.file = file; 
    }
    else {
      this.errors = 'File format is not supported, please update CSV or TXT.';
      this.aleartTimeOut(5000);
    }
  }

}
