<div class="row justify-content-center pb-4">
    <div class="col-md-6">
        <div class="card z-depth-1 mb-4 position-relative">
            <div class="badge-overlay">
                <span class="top-left badge">Info</span>
            </div>
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold brand-color text-center">Verifier</h6>
            </div>
            <div class="card-body">
                <p class="text mb-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam iure
                    rerum laudantium autem delectus aperiam ea laboriosam fugiat, labore
                </p>
                <div class="row">
                    <div class="col-12">
                        <p class="text-dark">
                            <i class="fas fa-check-circle text-primary me-1"></i> <strong> Undeliverable - </strong>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        </p>
                        <p class="text-dark">
                            <i class="fas fa-check-circle text-info me-1"></i> <strong> Deliverable - </strong>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                        </p>
                        <p class="text-dark">
                            <i class="fas fa-check-circle text-warning me-1"></i> <strong> Risky - </strong>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card z-depth-1 mb-4 position-relative">
            <div class="badge-overlay">
                <span class="top-left badge">Info</span>
            </div>
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold brand-color text-center">Email Finder</h6>
            </div>
            <div class="card-body">
                <p class="text ps-4 mb-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam iure
                    rerum laudantium autem delectus aperiam ea laboriosam fugiat, labore
                    pariatur in dolore cum, non sit similique voluptates maiores,
                    quibusdam!
                </p>
                <div class="row">
                    <div class="col-12">
                        <p class="text-dark">
                            <i class="fas fa-check-circle text-success me-1"></i> <strong> Not a valid Email Domain -
                            </strong>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        </p>
                        <p class="text-dark">
                            <i class="fas fa-check-circle text-primary me-1"></i> <strong> Email Found - </strong>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card z-depth-1 mb-4 position-relative">
            <div class="badge-overlay">
                <span class="top-left badge">Info</span>
            </div>
            <div class="card-header py-3 text-center">
                <h6 class="m-0 font-weight-bold brand-color">Pattern Finder</h6>
            </div>
            <div class="card-body">
                <p class="text ps-4 mb-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam iure
                    rerum laudantium autem delectus aperiam ea laboriosam fugiat, labore
                    pariatur in dolore cum, non sit similique voluptates maiores,
                    quibusdam!
                </p>
                <div class="row">
                    <div class="col-12">
                        <p class="text-dark">
                            <i class="fas fa-check-circle text-primary me-1"></i> <strong> Last Contacted - </strong>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        </p>
                        <p class="text-dark">
                            <i class="fas fa-check-circle text-danger me-1"></i> <strong> Email Accuracy - </strong>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>