import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResultsComponent } from './results/results.component';
import { ResultDataComponent } from './result-data/result-data.component';
import { LoginComponent } from './login/login.component';
import { FinderComponent } from './finder/finder.component'
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { LowVolumeComponent } from './low-volume/low-volume.component';
import { HighVolumeComponent } from './high-volume/high-volume.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LookupComponent } from './lookup/lookup.component';
 

const routes: Routes = [
  { path: '', redirectTo: 'high-volume', pathMatch: 'full' },
  { path: 'finder', component: FinderComponent, canActivate: [AuthGuard] },
  { path: 'lookup', component: LookupComponent, canActivate: [AuthGuard] },
  { path: 'low-volume', component: LowVolumeComponent, canActivate: [AuthGuard]  },  
  { path: 'high-volume', component: HighVolumeComponent, canActivate: [AuthGuard]  },
  { path: 'results', component: ResultsComponent, canActivate: [AuthGuard]  },
  { path: 'results/:id', component: ResultDataComponent, canActivate: [AuthGuard]  }, 
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]  },
  { path: 'login', component: LoginComponent },
];

// const routes: Routes = [
//   { path: '', redirectTo: 'high-volume', pathMatch: 'full' },
//   { path: 'finder', component: FinderComponent,},
//   { path: 'lookup', component: LookupComponent,},
//   { path: 'low-volume', component: LowVolumeComponent,},  
//   { path: 'high-volume', component: HighVolumeComponent,},
//   { path: 'results', component: ResultsComponent,},
//   { path: 'results/:id', component: ResultDataComponent, }, 
//   { path: 'dashboard', component: DashboardComponent, },
//   { path: 'login', component: LoginComponent },
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
