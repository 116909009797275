import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DownloadService } from '../services/download.service'
import { SocialAuthService } from 'angularx-social-login';
import { LoginService } from '../services/login.service';
import { AppCommon } from '../common/app-common';

export interface Item { batch_id: string; total_emails: any; created_at: any }

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  private itemsCollection: any;
  items: Observable<Item[]>;
  totalItems = 0;

  noDataFound = false;
  loading = false;
  googleUserID: any;

  constructor(private readonly afs: AngularFirestore,
    private router: Router,
    private downloadService: DownloadService,
    private loginService: LoginService
  ) {

    this.googleUserID = AppCommon.getUserId();
    this.itemsCollection = this.afs.collection<Item>('batches', ref =>
      ref.where('created_by', '==', this.googleUserID).orderBy('created_at', 'desc').limit(100));
    this.items = this.itemsCollection.valueChanges();

  }

  ngOnInit(): void {
    this.loading = true;
    this.items.subscribe(
      {
        next: data => {
          this.totalItems = data.length;
          this.loading = false;
          if (this.totalItems <= 0) {
            this.noDataFound = true;
          }

        },
        error: error => {
          console.log('There was an error! lenth not found', error)

        }
      });
  }


  getBatch(batch_id: string) {
    this.router.navigate(['/results', batch_id]);
  }

  exportBatch(batch_id: string) {
    if (batch_id) {
      var doanloadBatch = this.afs.collection('emails', ref => ref.where('batch_id', '==', batch_id)).valueChanges();
      doanloadBatch.subscribe({
        next: data => {
          data = data.sort((a, b) => { return (a as any).serial_number - (b as any).serial_number });
          this.downloadService.downloadFile(data, batch_id, 'all');
        },

        error: error => {
          console.log('There was an error while downloading all!', error)
        }
      }
      )
    }

  }

  deleteBatch(batch_id: string) {
    console.log(batch_id);
    var batchDoc = this.afs.doc<any>('batches/' + batch_id);
    batchDoc.delete();
    console.log("Deleted")
  }



}
