<!-- <div class="row mt-5">
    <div class="col text-center">
        <h3 style="color: #3f729b;">Finder</h3>
        <hr>
    </div>
</div>

<p style="text-align: center;">
    To utilize the email permutator feature improved by E-Gen Services for finding email addresses, <br>you must furnish
    the First name, Last name, and a valid email domain to identify valid email addresses.
</p> -->



<div class="container-fluid ">

    <div class="row d-flex justify-content-center mt-5">
        <div class="d-flex justify-content-center">
            <!-- <h4 class="header-text">Email Finder:</h4> -->
            <p class="mx-2">
                Use this feature to find probable email addresses if you have first name, last name and work email domain.
            </p>
        </div>

      
    
    </div>
    
    <hr class="m-0">


    <div class="row d-flex justify-content-center">

        <div class="col-12 mt-3">

            <form [formGroup]="finderFormGroup" (ngSubmit)="onSubmit()">

                <div class="row" >
                    <div class="col-2">
                        <div class="mb-2">
                            <label for="name" class="mb-0 mx-1"><small>First Name *</small></label>
                            <input type="text" placeholder="Mitchell" [ngClass]="formInputClasses"
                                formControlName="first_name" style="font-size: 14px;"/>
                            <div class="text-danger small mt-1 mx-1"
                                *ngIf="finderFormGroup.get('first_name').invalid && finderFormGroup.get('first_name').touched">
                                <span *ngIf="finderFormGroup.get('first_name').errors.required">First name is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="mb-2">
                            <label for="name" class="mb-0 mx-1"><small>Middle Name</small></label>
                            <input type="text" style="font-size: 14px;" placeholder="..." [ngClass]="formInputClasses" formControlName="middle_name" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="mb-2">
                            <label for="name" class="mb-0 mx-1"><small>Last Name *</small></label>
                            <input type="text" style="font-size: 14px;" placeholder="Speers" [ngClass]="formInputClasses" formControlName="last_name" />
                            <div class="text-danger small mt-1 mx-1"
                                *ngIf="finderFormGroup.get('last_name').invalid && finderFormGroup.get('last_name').touched">
                                <span *ngIf="finderFormGroup.get('last_name').errors.required">Last name is required.</span>
                            </div>
                        </div>
        
                    </div>
                    <div class="col-4">
                        <div class="mb-2">
                            <label for="name" class="mb-0 mx-1"><small>Work Email Domain *</small></label>
                            <input type="text" style="font-size: 14px;" placeholder="work-email-domain.com" [ngClass]="formInputClasses"
                                formControlName="company_domain" />
                            <div class="text-danger small mt-1 mx-1"
                                *ngIf="finderFormGroup.get('company_domain').invalid && finderFormGroup.get('company_domain').touched">
                                <span *ngIf="finderFormGroup.get('company_domain').errors.required">Email domain is
                                    required.</span>
                                <span *ngIf="finderFormGroup.get('company_domain').errors.invalidDomain">Email domain is not
                                    valid.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 d-flex justify-content-end">
                        <div class="mt-3 mr-2" >
                            <button type="submit" [disabled]="requestProcessing" [size]="'sm'"
                            mdbBtn color="dark" mdbWavesEffect class="btn btn-primary mt-2 m-0"
                                style="width: 135px;"><i class="fas fa-search"></i> <span class="mx-2">Find</span></button>
                        </div>
                    </div>
                </div>
    
                
                
    
            </form>

        </div>

        <div class="col-12 mt-2">
            <div class="alert alert-info z-depth-1 mt-4" role="alert" *ngIf="requestProcessing">
                <mdb-icon fas icon="spinner" class="fa-spin fa-1x"></mdb-icon> Processing your request...
            </div>
        </div>

      
    
        <div class="col-12 mt-2">
            
        
                <div class="text-center p-2" *ngIf="notLoadded">
                    <img class="no-result-image" src="assets/images/empty_data_tree.svg" >
                    <h3>Try refining your search</h3>
                    <h5 class="m-3 px-2">No results found. </h5>
                </div>
        
                <ol class="list-group list-group-light list-group-numbered mt-2 result-area">
                    <li class="list-group-item d-flex justify-content-between align-items-start shadow-sm mb-2"
                        *ngFor="let item of requestDataList | async">
                        <div class="ms-2 me-auto col-4">
                            <div style="padding-left: 8px;" 
                            class="page-small-text" id="{{item.id}}">{{item.id | uppercase }} at
                                {{item.created_at.seconds * 1000 | date: 'medium'}}
                            </div>
                            <div class="fw-bold mt-1">
                                <i class="far fa-user p-1"></i> {{item.first_name}} <span
                                    *ngIf="item.middle_name != ''">{{item.middle_name}}</span> {{item.last_name}}
                                    <i class="fas fa-arrow-right p-1"></i>
                                {{item.company_domain}}
                            </div>
        
                        </div>

                        <div class="col-2">
                            <!-- <app-arrow-steps 
                            [menuList]="menuStepList" 
                            [currentItem]="item.verifier"></app-arrow-steps> -->
                            <p *ngIf="item.verifier && item.is_found"
                            class="text-muted mb-0 page-small-text">{{item.verifier == 'MAP' ? 'Delivered':'Verified' }} by</p>
                            <span 
                            *ngIf="item.verifier && item.is_found"
                            class="badge badge-secondary p-1 px-2"> {{item.verifier}}
                            </span>
                            <span *ngIf="item.last_contacted" class="text-muted page-small-text mx-2">on {{getDate(item.last_contacted)}}</span>
                        </div>  

                        <div class="col-2">
                            <div *ngIf="item?.clv_response?.trace_status && item.is_found">
                                <p class="text-muted mb-0 page-small-text">CLV Status</p>
                                <div >
                                    <app-status-badge [status]="item.clv_response.trace_status"></app-status-badge>
                                </div>
                            </div>
                           
                        </div>

                        <div class="col-4 d-flex justify-content-end">

                            <div *ngIf="item.status == 'rejected'">
                                <span class="badge badge-danger p-2 mt-2
                                " >
                                    <mdb-icon fas icon="heart-broken"></mdb-icon> Not a valid email domain
                                </span>
                            </div>
        
                            <div *ngIf="item.status == 'processing'">
                                <span class="badge badge-warning p-2 mt-2
                                " style="cursor: pointer;">
                                    <mdb-icon fas icon="spinner" class="fa-spin fa-1x"></mdb-icon> Processing
                                </span>
                            </div>
            
                            <div *ngIf="item.status == 'completed'">
                                <div *ngIf="!item.email_address else notFound;">
                                    <div class="">
                                        <p class="mb-0 text-right"> Unable to find</p>
                                        <p class="mb-0"><span class="badge badge-primary p-1
                                           " style="cursor: pointer;" 
                                            (click)="goToSuggestion(item.company_domain)">Get Domain Patterns</span></p>
                                    </div>
                                </div>
                                <ng-template #notFound>
                                    <div class="pt-1">
            
            
                                        <span class="badge-light shadow-sm p-2">{{item.email_address}}</span>
            
            
                                        <button (click)="copy(item.email_address)" type="button" mdbBtn color="secondary" size="sm" class="copy-btn" mdbWavesEffect><i
                                                class="far fa-copy"></i></button>
            
                                    </div>
            
                                </ng-template>
                            </div>


                        </div>
        

        
        
        
                    </li>
        
        
                </ol>
       
    
        </div>
    
    </div>

    <!-- <div class="row">

        <div class="col-3 mt-2">
    
            <form [formGroup]="finderFormGroup" (ngSubmit)="onSubmit()">
    
                <div class="fields">
    
                    <div class="mb-2">
                        <label for="name" class="mb-0 mx-1"><small>First Name *</small></label>
                        <input type="text" placeholder="Mitchell" [ngClass]="formInputClasses"
                            formControlName="first_name" />
                        <div class="text-danger small mt-1 mx-1"
                            *ngIf="finderFormGroup.get('first_name').invalid && finderFormGroup.get('first_name').touched">
                            <span *ngIf="finderFormGroup.get('first_name').errors.required">First name is required.</span>
                        </div>
                    </div>
    
                    <div class="mb-2">
                        <label for="name" class="mb-0 mx-1"><small>Middle Name</small></label>
                        <input type="text" placeholder="Lee" [ngClass]="formInputClasses" formControlName="middle_name" />
                    </div>
    
                    <div class="mb-2">
                        <label for="name" class="mb-0 mx-1"><small>Last Name *</small></label>
                        <input type="text" placeholder="Speers" [ngClass]="formInputClasses" formControlName="last_name" />
                        <div class="text-danger small mt-1 mx-1"
                            *ngIf="finderFormGroup.get('last_name').invalid && finderFormGroup.get('last_name').touched">
                            <span *ngIf="finderFormGroup.get('last_name').errors.required">Last name is required.</span>
                        </div>
                    </div>
    
                    <div class="mb-2">
                        <label for="name" class="mb-0 mx-1"><small>Work Email Domain *</small></label>
                        <input type="text" placeholder="work-email-domain.com" [ngClass]="formInputClasses"
                            formControlName="company_domain" />
                        <div class="text-danger small mt-1 mx-1"
                            *ngIf="finderFormGroup.get('company_domain').invalid && finderFormGroup.get('company_domain').touched">
                            <span *ngIf="finderFormGroup.get('company_domain').errors.required">Email domain is
                                required.</span>
                            <span *ngIf="finderFormGroup.get('company_domain').errors.invalidDomain">Email domain is not
                                valid.</span>
                        </div>
                    </div>
                </div>
    
                <div class="mt-3" >
                    <button type="submit" [disabled]="requestProcessing" mdbBtn color="dark" mdbWavesEffect class="btn btn-primary  m-0"
                        style="width: 135px;"><i class="fas fa-search"></i> <span class="mx-2">Find</span></button>
                </div>
    
            </form>
    
            <div class="alert alert-info z-depth-1 mt-4" role="alert" *ngIf="requestProcessing">
                <mdb-icon fas icon="spinner" class="fa-spin fa-1x"></mdb-icon> Processing your request...
            </div>
    
        </div>
    
        <div class="col-9 mt-4">
            
        
                <div class="text-center p-2" *ngIf="notLoadded">
                    <img class="no-result-image" src="assets/images/empty_data_tree.svg" >
                    <h3>Try refining your search</h3>
                    <h5 class="m-3 px-2">No results found. </h5>
                </div>
        
                <ol class="list-group list-group-light list-group-numbered mt-2 result-area">
                    <li class="list-group-item d-flex justify-content-between align-items-start shadow-sm mb-2"
                        *ngFor="let item of requestDataList | async">
                        <div class="ms-2 me-auto">
                            <div style="font-size: 10px; color: grey; padding-left: 8px;">{{item.id | uppercase }} at
                                {{item.created_at.seconds * 1000 | date: 'medium'}}
                            </div>
                            <div class="fw-bold mt-1">
                                <i class="far fa-user p-1"></i> {{item.first_name}} <span
                                    *ngIf="item.middle_name != ''">{{item.middle_name}}</span> {{item.last_name}} <i
                                    class="fas fa-at p-1"></i>
                                {{item.company_domain}}
                            </div>
        
                        </div>
        
                        <div *ngIf="item.status == 'rejected'">
                            <span class="badge badge-danger p-2 mt-2
                            " >
                                <mdb-icon fas icon="heart-broken"></mdb-icon> Not a valid email domain
                            </span>
                        </div>
    
                        <div *ngIf="item.status == 'processing'">
                            <span class="badge badge-warning p-2 mt-2
                            " style="cursor: pointer;">
                                <mdb-icon fas icon="spinner" class="fa-spin fa-1x"></mdb-icon> Processing
                            </span>
                        </div>
        
                        <div *ngIf="item.status == 'completed'">
                            <div *ngIf="!item.email_address else notFound;">
                                <div class="">
                                    <p class="mb-0 text-right"> No valid suggestions</p>
                                    <p class="mb-0"><span class="badge badge-primary p-1
                                       " style="cursor: pointer;" 
                                        (click)="goToSuggestion(item.company_domain)">Get Domain Patterns</span></p>
                                </div>
                            </div>
                            <ng-template #notFound>
                                <div class="pt-1">
        
        
                                    <span class="badge badge-success shadow-sm p-2">{{item.email_address}}</span>
        
        
                                    <button (click)="copy(item.email_address)" type="button" mdbBtn color="secondary" size="sm" class="copy-btn" mdbWavesEffect><i
                                            class="far fa-copy"></i></button>
        
                                </div>
        
                            </ng-template>
                        </div>
        
        
        
                    </li>
        
        
                </ol>
       
    
        </div>
    
    </div> -->
</div>

