import { Component, OnInit } from '@angular/core';
import { AppCommon } from '../common/app-common';

@Component({
  selector: 'app-high-volume',
  templateUrl: './high-volume.component.html',
  styleUrls: ['./high-volume.component.scss']
})
export class HighVolumeComponent implements OnInit {

  googleUserID: any;
  

  constructor() {
  }


  ngOnInit(): void {
    this.googleUserID = AppCommon.getUserId(); 
  }

}
