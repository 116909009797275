import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

// Custom validator function for domain name validation
export function domainValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      // If the control value is empty, return null (valid)
      return null;
    }

    // Regular expression pattern to match domain names
    const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Check if the control value matches the domain pattern
    if (!domainPattern.test(control.value)) {
      // Return validation error if the domain name is invalid
      return { invalidDomain: true };
    }

    // Return null if the domain name is valid
    return null;
  };
}
