import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service'
import { AppCommon } from 'src/app/common/app-common';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { YoutubePlayComponent } from '../youtube-play/youtube-play.component';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {


  logoSrc = '/assets/images/logo-mini.png'
  isLoggedin: boolean = false;
  loggedinUser: any;
  modalYoutubeRef: MDBModalRef | null = null;

  youtube_videos = [
    {id: 1, link: 'https://www.youtube.com/embed/psPmwLpEJPM?si=c54Y9585oYN7qcys', name: "Email Finder"},
    {id: 2, link: 'https://www.youtube.com/embed/AMQ2CWnMPEg?si=5lhPX_OMuGFNLX60', name: "Pattern Finder"},
    {id: 3, link: 'https://www.youtube.com/embed/-4r0FMBK2ak?si=S78Y64DerNvQAzhY', name: "Verifier (Low)"},
    {id: 4, link: 'https://www.youtube.com/embed/fo75kW7a3hY?si=hl1YwcLAQtLjiaxx', name: "Verifier (High)"},
  ]

  constructor(private router: Router, private loginService: LoginService, 
    private modalService: MDBModalService
  ) {

  }

  ngOnInit(): void {
    this.isLoggedin = this.loginService.isLoggedIn();
    this.loggedinUser = AppCommon.getUserDataObject();
    this.subscribeToLoginAndLogoutEvents();
  }

  subscribeToLoginAndLogoutEvents() {

    this.loginService.userLoginEvent.subscribe(res => {
      console.log("login: ",res )
      if (res == true) { 
        this.isLoggedin = this.loginService.isLoggedIn();
        this.loggedinUser = AppCommon.getUserDataObject();
      }
    });


    this.loginService.userLogoutEvent.subscribe(res => {
      console.log("logout: ",res )
      if (res == true) { 
        
        this.isLoggedin = false; 
      }
    });

  }


  logout(): void {
    this.loginService.signOut();
  }


  openYoutubeModal(video){
    this.modalYoutubeRef = this.modalService.show(YoutubePlayComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-lg',
      containerClass: '',
      animated: true,
      data: { 
        youtube_link: video.link,
        youtube_name: video.name,
      }
    });
  }

}
