import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClvApiService } from '../services/clv-api.service';
import { ApiService } from '../common/api-service';
import { AppCommon } from '../common/app-common';
import { ActivatedRoute, Router } from '@angular/router';
import { domainValidator } from '../common/domain-validator';


@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss'],
  providers: [ApiService, ClvApiService]
})
export class LookupComponent implements OnInit {

  domainForm: FormGroup;
  isLoading: boolean = false;
  showNoResults: boolean = false;
  showApiError: boolean = false;
  selectedDomain: string = '';
  dataList = [];
  emailList = [];
  backupEmailList = [];
  loggedinUser: any;
  selectedFilter: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private clvApiService: ClvApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.domainForm = this.formBuilder.group({
      domain: ['', [Validators.required, domainValidator()]] // Use Validators if needed
    });

    this.loggedinUser = AppCommon.getUserDataObject();
  } 


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if ( !(
        (params['domain'] == null) || (params['domain'] == undefined)
      )) {
        this.domainForm.patchValue({ "domain": params['domain']});
        this.onSubmit();

        // this.router.navigate(['/lookup'], {
        //   queryParams: {},
        //   queryParamsHandling: 'merge', // or 'preserve' if you want to keep other query params
        //   preserveFragment: true, // if you have fragments
        //   replaceUrl: true // optional, if you want to replace the current URL
        // });

      }

    });
  }

  copy(value){
    AppCommon.copyToClipboard(value)
  }



  startStopLoading(){
    this.isLoading =  !this.isLoading;
  }

  getDate(last_contacted){
    return AppCommon.getFormattedDate(last_contacted, true)
  }

  getPercentage(item){
    const percentage = 95;
    const dateString = item.last_contacted;
    const [day, month, year] = dateString.split(/[\s:-]+/); 
    const parsedDate = new Date(year, month - 1, day, 0, 0, 0);
    const currentDate = new Date().getTime();
    const timeDifference = currentDate - parsedDate.getTime();
    const weeksDifference = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000));

    // weeks
    if (weeksDifference < 20){
      percentage + 5
    } else if (weeksDifference > 20 && weeksDifference < 50) {
      percentage + 4
    } else if (weeksDifference > 50 && weeksDifference < 75) {
      percentage + 3
    } else if (weeksDifference > 75 && weeksDifference < 100) {
      percentage + 2
    } else {
      percentage + 1
    }
    return percentage
  }


  onSubmit() {
    if (this.domainForm.valid) {
      this.startStopLoading(); 
      this.clvApiService.parentLookupComponent = this;
      this.clvApiService.emailListbyDomain(this.domainForm.value.domain, this.loggedinUser?.email);
    } else {
      this.domainForm.markAllAsTouched();
    }
  }

  dataReceived(e){
    this.dataList = e?.results;
    if (this.dataList.length > 0) {
      var emails = [];
      for (let i = 0; i < this.dataList.length; i++) {
          const entries = this.dataList[i].value;
          for (let j = 0; j < entries.length; j++) {
              const emailObject = entries[j];
              emailObject["pattern"] = this.dataList[i].key;
              emails.push(emailObject);
          }
      }
      this.emailList = emails;
    } else {
      this.showNoResults = true;
      this.clearShowNoResults();
    }
    this.startStopLoading(); 
  }

  clearShowNoResults(){
    setTimeout(() => {
      this.showNoResults = false;
    }, 5000);
  }

  errorReceived(){
    this.startStopLoading(); 
      this.showApiError = true;
      setTimeout(() => {
        this.showApiError = false;
      }, 5000);
  }

  filterEmail(pattern: string){
      if (this.selectedFilter.length > 0){
        this.emailList = this.backupEmailList;
      } else {
        this.backupEmailList = [...this.emailList];
      }
      this.selectedFilter = pattern;
      this.emailList = this.emailList.filter(el => el['pattern'] == this.selectedFilter);
  }

  clearFilter(){
    this.selectedFilter = '';
    this.emailList = this.backupEmailList;
  }
  
}
