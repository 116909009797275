

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAIJy7ni21AUAziyFBw6-p2GnufUH_TbsM",
    authDomain: "bf-clv.firebaseapp.com",
    // databaseURL: '<your-database-URL>',
    projectId: "bf-clv",
    storageBucket: "bf-clv.appspot.com",
    messagingSenderId: "427807550157",
    appId: "1:427807550157:web:a6e94356cd7739cfca9784"
    // measurementId: '<your-measurement-id>'
  },
  clv_backend_service: "https://api.clv.buyerforesight.net"
  // clv_backend_service: "http://localhost:8080"
  
};
 