export class AppCommon {


    constructor() { }

    static setUserDataObject(e: any) {
        var data = JSON.stringify(e)
        localStorage.setItem("clv_user_data", data);
    }

    static getUserDataObject() {
        var data = localStorage.getItem("clv_user_data") || undefined;
        if (data != undefined) {
            data = JSON.parse(data)
        }
        return data;
    }

    static setUserId(e: any) {
        localStorage.setItem("clv_user", String(e));
    }

    static setUserUpdated(e: any) {
        localStorage.setItem("user_updated", String(e));
    }

    static isUserDataUpdated() {
        var data: any = localStorage.getItem("user_updated") || false;
        if (data != false) {
            data = Boolean(data)
        }
        return data;
    }


    static getUserId() {
        var data: any = localStorage.getItem("clv_user") || undefined;
        return data;
    }


    static copyToClipboard(val: string){
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
      }

  
      static getFormattedDate(last_contacted, datesWithWeeks){
        const dateString = last_contacted;
        const [day, month, year] = dateString.split(/[\s:-]+/);
        const parsedDate = new Date(year, month - 1, day, 0, 0, 0);
        const currentDate = new Date().getTime();
        const timeDifference = currentDate - parsedDate.getTime();
        const weeksDifference = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000));
        if(datesWithWeeks){
            return day + '-' + month + '-' + year + " (" + weeksDifference + " weeks ago" + ")"
        } else {
            return day + '-' + month + '-' + year
        }
       
      }



}