import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';

// MDBBootstrap
import { MDBBootstrapModule } from 'angular-bootstrap-md';

// Components
import { ResultsComponent } from './results/results.component';
import { UploadformComponent } from './high-volume/uploadform/uploadform.component';
import { TextformComponent } from './high-volume/textform/textform.component';
import { ResultDataComponent } from './result-data/result-data.component';
import { LoginComponent } from './login/login.component';
import { FinderComponent } from './finder/finder.component';
import { LowVolumeComponent } from './low-volume/low-volume.component';
import { HighVolumeComponent } from './high-volume/high-volume.component';

// HTTP
import { HttpClientModule } from '@angular/common/http';

// AngularFire
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';

// Environment
import { environment } from '../environments/environment';

// Services 
import { LoginService } from './services/login.service';

// Auth
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider} from 'angularx-social-login';

// Download
import { DownloadService } from './services/download.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LookupComponent } from './lookup/lookup.component';
import { ComponentsModule } from './components/components.module';
import { ClvApiService } from './services/clv-api.service';
import { ApiService } from './common/api-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserLeaderboardComponent } from './dashboard/user-leaderboard/user-leaderboard.component';
import { DashStatCardComponent } from './dashboard/dash-stat-card/dash-stat-card.component';
import { InfoCardComponent } from './dashboard/info-card/info-card.component';


@NgModule({
  declarations: [
    AppComponent,
    ResultsComponent,
    UploadformComponent,
    TextformComponent,
    ResultDataComponent,
    LoginComponent, 
    FinderComponent, 
    LowVolumeComponent, 
    HighVolumeComponent, 
    DashboardComponent, 
    LookupComponent, UserLeaderboardComponent, DashStatCardComponent, InfoCardComponent, 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    // Auth
    SocialLoginModule,

    // AngularFire
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule, 

    // MDBBootstrap
    MDBBootstrapModule.forRoot(),
    ComponentsModule

  ],
  providers: [ 
    { provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '427807550157-mgqei6jcfcoaoavk29tb22sl1e00o1sf.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig, }, 
      
    ApiService,
    LoginService, 
    DownloadService,
    ClvApiService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
