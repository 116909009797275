import { Injectable } from '@angular/core';
import { ApiService } from '../common/api-service';
import { ApiServiceDelegate } from '../common/api-service-delegate';
import { ApiCollection } from '../common/api-collection';
import { FinderComponent } from '../finder/finder.component';
import { HttpClient } from '@angular/common/http';
import { TextformComponent } from '../high-volume/textform/textform.component';
import { UploadformComponent } from '../high-volume/uploadform/uploadform.component';
import { LowVolumeComponent } from '../low-volume/low-volume.component';
import { LookupComponent } from '../lookup/lookup.component';

@Injectable({
  providedIn: 'root'
})
export class ClvApiService {

  parentFinderComponent: FinderComponent;
  parentTextformComponent: TextformComponent;
  parentUploadformComponent: UploadformComponent;
  parentLowVolumeComponent: LowVolumeComponent;
  parentLookupComponent: LookupComponent;

  headers = {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'};

  constructor(private apiService: ApiService, private http: HttpClient){ }

  postEmails(batch_id:string, emails:string[], volume: string, caller: string){
    const body = {"batch_id": batch_id, "emails": emails, "volume": volume};
    let d: ApiServiceDelegate = {
      Data: body,
      Method: "POST",
      Action: ApiCollection.CLV_ADD_EMAILS,
      Success: (e: any) => {
        if (caller == 'app-textform'){
          this.parentTextformComponent.publishedSuccess(batch_id);
        }
        if (caller == 'app-uploadform'){
          this.parentUploadformComponent.publishedSuccess(batch_id);
        }
        if (caller == 'app-low-volume'){
          this.parentLowVolumeComponent.publishedSuccess(batch_id);
        }
        
        return e;
      },
      Error: (e: String) => { 
        if (caller == 'app-textform'){
          this.parentTextformComponent.errorPublishingData(batch_id, e);
        }
        if (caller == 'app-uploadform'){
          this.parentUploadformComponent.errorPublishingData(batch_id, e);
        }
        if (caller == 'app-low-volume'){
          this.parentLowVolumeComponent.errorPublishingData(batch_id, e);
        }
        return e;
      },
    };
    this.apiService.callService(d);
  }

  addFinderMXTracing(payload){
    let d: ApiServiceDelegate = {
      Data: {domain: payload['company_domain']},
      Method: "POST",
      Action: ApiCollection.CLV_MX_TRACING,
      Success: (e: any) => {
        this.parentFinderComponent.mxtracingReceived(payload, e);
        return e;
      },
      Error: (e: String) => { 
        this.parentFinderComponent.mxtracingError(payload);
        return e;
      },
    };
    this.apiService.callService(d);
  }

  addFinderRequestToEgen(payload){
    let d: ApiServiceDelegate = {
      Data: payload,
      Method: "POST",
      Action: ApiCollection.CLV_EGEN_MAKE_REQUEST,
      Success: (e: any) => {
        this.parentFinderComponent.requestAdded();
        return e;
      },
      Error: (e: String) => { 
        this.parentFinderComponent.errorWhileadding(payload['id']);
        return e;
      },
    };
    this.apiService.callService(d);
  }
  

  emailListbyDomain(domain, user_email){
    let d: ApiServiceDelegate = {
      Data: {"domain": domain, "user": user_email},
      Method: "POST",
      Action: ApiCollection.CLV_LOOKUP_BY_DOMAIN,
      Success: (e: any) => {
        this.parentLookupComponent.dataReceived(e);
        return e;
      },
      Error: (e: String) => { 
        console.log("error: ", e)
        this.parentLookupComponent.errorReceived();
        return e;
      },
    };
    this.apiService.callService(d);
  }
}
