
<div class="row mt-5">
    <div class="col d-flex justify-content-between">
        <h3 style="color: #3f729b;" class="mb-0">High Volume</h3>
        <p class="mb-0">
            In this mode test anywhere between 10-1000 email addresses. Tentative response in ~3 minutes for upto 350 emails.
        </p>
      
    </div>
</div>
<hr class="mt-1">



<div class="row pt-1" style="height: 100px;">

    <div class="col-5">
        <app-uploadform [googleUserID]="googleUserID"></app-uploadform>
    </div>

    <div class="col-2 d-flex flex-column" style="padding-top: 70px;">
   
             <div class="row">
                <hr style="width:100px; transform:rotate(90deg);">
             </div>
             <div class="row" style="margin: 0px; margin-top: 100px;">
                <div class="col">
                    <h3 style="text-align: center;">OR</h3>
                </div>
             </div>
             <div class="row" style="margin-top: 100px;">
                <hr style="width:100px; transform:rotate(90deg);">
             </div>
       
    </div>

    <div class="col-5">
        <app-textform [googleUserID]="googleUserID"></app-textform>
    </div>

</div>
 