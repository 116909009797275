import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { domainValidator } from '../common/domain-validator';
import { ClvApiService } from '../services/clv-api.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { ApiService } from '../common/api-service';
import { FinderRequestModel } from '../models/finderModel';
import { AppCommon } from '../common/app-common';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-finder',
  templateUrl: './finder.component.html',
  styleUrls: ['./finder.component.scss'],
  providers: []
})

export class FinderComponent implements OnInit {

  finderFormGroup: FormGroup;
  googleUserID: any = null;
  formInputClasses = 'form-control border border-light shadow-sm';
  requestDataList: Observable<FinderRequestModel[]>;
  private requestCollection: AngularFirestoreCollection<FinderRequestModel>;
  private firestoreCollectionName = "clvFinder";
  requestProcessing: boolean = false;
  notLoadded = true;
  loggedinUser: any;
  menuStepList=['MAP', 'CLV', 'GMAIL']

  constructor(
    private readonly afs: AngularFirestore,
    private clvApiService: ClvApiService,
    private router: Router
  ) {  
    this.initForm();
    this.googleUserID = Number(AppCommon.getUserId());
    this.loggedinUser = AppCommon.getUserDataObject();
    // this.requestCollection = afs.collection<FinderRequestModel>(this.firestoreCollectionName);

  }

  initForm():void {
    this.finderFormGroup = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      middle_name: new FormControl('', []),
      last_name: new FormControl('', [Validators.required]),
      company_domain: new FormControl('', [Validators.required, domainValidator()]),
    });
  }

  ngOnInit(): void {
    this.clvApiService.parentFinderComponent = this;
    this.initData();
  }

  initData() {
    this.requestCollection = this.afs.collection<FinderRequestModel>(this.firestoreCollectionName, ref =>
      ref.where('created_by', '==', this.googleUserID).orderBy('created_at', 'desc').limit(100));
    this.requestDataList = this.requestCollection.valueChanges();
    this.requestDataList.subscribe(
      {
        next: data => {
          if (data.length > 0){
            this.notLoadded = false;
          }
        }
      }
    )
  }

  getPayload() {
    var payload = this.finderFormGroup.value;
    payload['id'] = this.afs.createId();
    payload['created_at'] = new Date();
    payload['status'] = 'processing';
    payload['is_found'] = false;
    payload['created_by'] = this.googleUserID;
    payload['email_address'] = "";
    payload['company_name'] = "";
    return payload

  }

  mxtracingReceived(payload, e) {
    payload['mx_tracing'] = e;
    payload['user'] = this.loggedinUser?.email;
    const code = e?.code;
    if ([9999, 9997, 9994].includes(code)) {
      payload['status'] = 'rejected';
      this.requestCollection.doc(payload['id']).set(payload);
      this.requestAdded();
    } else {
      this.requestCollection.doc(payload['id']).set(payload);
      // Delay for 
      setTimeout(() => {
        this.clvApiService.addFinderRequestToEgen(payload);
      }, 500);
    }

  }

  mxtracingError(payload) {
    payload['mx_tracing'] = {};
    this.requestCollection.doc(payload['id']).set(payload); 
    // Delay for 
    setTimeout(() => {
      this.clvApiService.addFinderRequestToEgen(payload);
    }, 500);
    this.requestAdded();
  }

 


  onSubmit() {
    if (this.finderFormGroup.valid) {
      this.requestProcessing = true;
      var payload = this.getPayload();
      this.clvApiService.addFinderMXTracing(payload);
    } else {
      // Form is invalid, show error messages
      this.finderFormGroup.markAllAsTouched();
    }
  }

  requestAdded() {
    this.requestProcessing = false;
  }

  copy(value) {
    AppCommon.copyToClipboard(value)
  }

  goToSuggestion(company_domain) {
    const url = `/lookup?domain=${company_domain}`;
    this.router.navigateByUrl(url)
  }

  errorWhileadding(id) {
    this.requestProcessing = false;
    this.requestCollection.doc(id).delete();
  }

  getDate(last_contacted){
    return AppCommon.getFormattedDate(last_contacted, false);
  }

}
