import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { Email } from '../models/dataModel'
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DownloadService } from '../services/download.service'

export interface Batch { total_emails: number; }
export interface Status { email: string; status: string; }
export interface BatchDetail { totalEmails: number; valids: number; invalids: number; risky: number }

@Component({
  selector: 'app-result-data',
  templateUrl: './result-data.component.html',
  styleUrls: ['./result-data.component.scss']
})
export class ResultDataComponent implements OnInit {

  private batchDoc: AngularFirestoreDocument<Batch>;
  batch: Observable<any>;

  batchId: any;
  private itemsCollection: AngularFirestoreCollection<Email>;
  items: Observable<Email[]>;

  totalTestedCount = 0;
  deliverableCount = 0;
  undeliverableCount = 0;
  riskyCount = 0;
  progressPercentage = 0;
  
  totalTestingEmailsCount = 0;

  constructor(private route: ActivatedRoute, private afs: AngularFirestore,
    private router: Router,
    private location: Location,
    private downloadService: DownloadService

  ) {
    this.batchId = this.route.snapshot.paramMap.get('id');
    this.batchDoc = this.afs.doc<Batch>('batches/' + this.batchId);
    this.batch = this.batchDoc.valueChanges();
    this.subscribeToTotalEmails();
  }
 

  ngOnInit(): void {
    this.itemsCollection = this.afs.collection('emails', ref => ref.where('batch_id', '==', this.batchId).orderBy('serial_number', 'asc'));
    this.items = this.itemsCollection.valueChanges();    
    this.subscribeToDataList();
  }

  subscribeToTotalEmails() {
    this.batch.subscribe( {
      next: data => {
        this.totalTestingEmailsCount = data.total_emails
      }
    })
  }

  
  updateProgressData() {
    this.progressPercentage = (this.totalTestedCount / this.totalTestingEmailsCount) * 100;
    this.progressPercentage = Math.floor(this.progressPercentage);
  }

  getStatusHelpValue(status){
    var message = ""
    if(status === 'Risky'){
      message = "Recipient mail server declined to confirm at this moment."
    }
    if(status === 'Valid-But-Not-Deliverable'){
      message = "Email is correct but mailbox is full"
    }
    return message
  }

  goToSuggestion(company_domain) {
    const domain = company_domain.split("@")[1]
    const url = `/lookup?domain=${domain}`;
    this.router.navigateByUrl(url)
  }


  subscribeToDataList() {
    this.items.subscribe(
      {
        next: data => {
          this.totalTestedCount = data.length;
          this.deliverableCount = data.filter(x => x.status === 'Deliverable').length;
          this.riskyCount = data.filter(x => x.status === 'Risky').length;
          this.undeliverableCount = this.totalTestedCount - (this.deliverableCount + this.riskyCount);
          this.updateProgressData()
          // this.chartDatasets = [ { data: [this.deliverableCount,this.undeliverableCount,this.riskyCount], label: 'CLV Results' } ];

        },
        error: error => {
          console.log('There was an error!', error)

        }
      })
  }


  download(filter: string = '') {
    this.items.subscribe({
      next: data => {
        data = data.sort((a, b) => (a.serial_number > b.serial_number) ? 1 : -1);
        this.downloadService.downloadFile(data, this.batchId, filter);
      },

      error: error => {
        console.log('There was an error while downloading all!', error)
      }
    })
  }



  deleteBatch(batch_id: string) {
    console.log(batch_id);
    this.batchDoc.delete();
    this.router.navigate(['/results']);
  }


  back(): void {
    this.location.back()
  }


}
