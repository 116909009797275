import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Batch } from '../models/dataModel'; 
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  googleUserID = String(localStorage.getItem('clv_user'));
  private itemsCollection: AngularFirestoreCollection<Batch>; 
  batch: Observable<any>;
  emailItems: Observable<any>;
  totalEmails = 0;

  constructor(
    private readonly afs: AngularFirestore
  ) { 
    this.itemsCollection = this.afs.collection<Batch>('smallBatches');
    this.batch = this.itemsCollection.doc(this.googleUserID).valueChanges(); 
    const userTestLocation = 'smallBatches/' + this.googleUserID + '/emails';
    this.emailItems = this.afs.collection(userTestLocation, ref =>       
      ref.orderBy('created_at', 'desc').limit(10)).valueChanges();  

      this.emailItems.subscribe({
        next: data => {
          this.totalEmails = data.length;
        },
        error: error => {
  
        }
      });
  }

  ngOnInit(): void {
    console.log(this.googleUserID)
  }

}
